#tab-bar {
  margin-bottom: 35px;

  a {
    font-size: 16px;
    text-transform: uppercase;
    cursor: pointer;
    color: black;
    text-decoration: none;
    padding: 3px 0;
    margin-right: 10px;
  }

  .selected {
    font-weight: 600;
    border-bottom: 3px solid #006ed2;
  }
}

@media print {
  #tab-bar {
    display: none !important;
  }
}
