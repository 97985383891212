@import "../../../less/variables.less";

.somesprites() {
  background-image: url('../../../public/img/some_sprites.png');
}

.sprite-position(@sprite) {
  @sprite-offset-x: extract(@sprite, 1);
  @sprite-offset-y: extract(@sprite, 2);
  background-position: @sprite-offset-x @sprite-offset-y;
}

.sprite-width(@sprite) {
  width: extract(@sprite, 3);
}
.sprite-height(@sprite) {
  height: extract(@sprite, 4);
}

.sprite(@sprite) {
  .sprite-position(@sprite);
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  .sprite-width(@sprite);
  .sprite-height(@sprite);
}

.footer-img-sprite(@sprite, @url) {
  &[href='@{url}'] {
    &:before {
      .somesprites();
      .sprite(@sprite);
      content: ' ';
      vertical-align: middle;
      display: inline-block;
    }
    > span {
      padding-left: 12px;
    }
  }
}

.footer {
  // Sprite coordinates
  @twitter: -5px, -5px, 35px, 35px;
  @digabi-hover: -5px, -50px, 35px, 35px;
  @facebook-hover: -5px, -95px, 35px, 35px;
  @facebook: -5px, -140px, 35px, 35px;
  @github-hover: -5px, -185px, 35px, 35px;
  @github: -5px, -230px, 35px, 35px;
  @twitter-hover: -5px, -275px, 35px, 35px;
  @digabi: -5px, -320px, 35px, 35px;
  @uutiskirje-hover: -5px, -365px, 35px, 35px;
  @uutiskirje: -5px, -410px, 35px, 35px;
  @vimeo-hover: -5px, -455px, 35px, 35px;
  @vimeo: -5px, -500px, 35px, 35px;
  @ytl-hover: -5px, -545px, 35px, 35px;
  @ytl: -5px, -590px, 35px, 35px;
  @updates: -5px -680px 35px 35px;
  @updates-hover: -5px -635px 35px 35px;
  @terms: -5px -410px 35px 35px;
  @terms-hover: -5px -365px 35px 35px;

  color: @base-brand-blue;
  font-size: 16px;
  font-weight: 400;
  padding: 60px 0;

  img {
    margin-right: 12px;
  }
  p {
    margin-bottom: 1px;
    margin-top: 0;
    line-height: 22px;
  }
  a:hover {
    text-decoration: none;
  }
  h5 {
    margin-top: 0;
    color: @base-color-almost-black;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 12.5px;
  }
  a {
    text-decoration: none;
  }

  a.with-logo {
    .footer-img-sprite(@ytl, 'https://ylioppilastutkinto.fi/');
    .footer-img-sprite(@digabi, 'https://digabi.fi/');
    .footer-img-sprite(@uutiskirje, 'https://digabi.fi/digabi/uutiskirje/');
    .footer-img-sprite(@vimeo, 'http://vimeo.com/user19863470');
    .footer-img-sprite(@github, 'https://github.com/digabi');
    .footer-img-sprite(@facebook, 'https://www.facebook.com/ylioppilastutkinto');
    .footer-img-sprite(@twitter, 'https://twitter.com/YTLSEN');
    .footer-img-sprite(@updates, 'https://www.abitti.fi/fi/paivitykset/');
    .footer-img-sprite(@terms, 'https://www.abitti.fi/fi/kayttoehdot/');
    & + a {
      margin-left: 6px;
    }
  }
  a.with-logo:hover {
    .footer-img-sprite(@ytl-hover, 'https://ylioppilastutkinto.fi/');
    .footer-img-sprite(@digabi-hover, 'https://digabi.fi/');
    .footer-img-sprite(@uutiskirje-hover, 'https://digabi.fi/digabi/uutiskirje/');
    .footer-img-sprite(@vimeo-hover, 'http://vimeo.com/user19863470');
    .footer-img-sprite(@github-hover, 'https://github.com/digabi');
    .footer-img-sprite(@facebook-hover, 'https://www.facebook.com/ylioppilastutkinto');
    .footer-img-sprite(@twitter-hover, 'https://twitter.com/YTLSEN');
    .footer-img-sprite(@updates-hover, 'https://www.abitti.fi/fi/paivitykset/');
    .footer-img-sprite(@terms-hover, 'https://www.abitti.fi/fi/kayttoehdot/');
  }

  .footer-column {
    vertical-align: top;
    width: 31%;
    min-width: 240px;
    height: 140px;
    display: inline-block;
    &:not(:first-child) {
      border-left: 1px solid @base-brand-blue;
      padding-left: 40px;
    }
    &:first-child > div {
      float: right;
      padding-right: 40px;
    }
    h5 {
      text-transform: uppercase;
    }
    &.wide-column {
      width: 37%;
      min-width: 290px;
      p {
        margin-bottom: 6px;
      }
    }
  }
}